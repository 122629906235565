import axiosIns from '@/libs/axios'
import { apiStatus } from '@/enums'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import coreService from '@/libs/api-services/core-service'

const getDefaultState = () => ({
  issues: [],
  issues_status: apiStatus.INIT,
  selected_issue: {},
  selected_issue_comments: [],
  selected_comment: {},
  target: {},
  update_fn: null,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_ISSUES: (state, uncertainties) => { state.issues = uncertainties },
    SET_SELECTED_ISSUE: (state, selIssue) => { state.selected_issue = selIssue },
    SET_UNCERTAINTIES_STATUS: (state, issuesStatus) => { state.issues_status = issuesStatus },
    SET_GLOBAL_TARGET: (state, payload) => { state.target = payload },
    SET_UPDATE_FN: (state, updateFn) => { state.update_fn = updateFn },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    setGlobalTarget: ({ commit }, target) => { commit('SET_GLOBAL_TARGET', target) },
    setUpdateFn: ({ commit }, updateFn) => { commit('SET_UPDATE_FN', updateFn) },
    setSelectedIssue: ({ commit }, issue) => { commit('SET_SELECTED_ISSUE', issue) },
    clearIssues: ({ commit }) => { commit('CLEAR_ALL') },
    getIssues: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      commit('SET_UNCERTAINTIES_STATUS', apiStatus.LOADING)
      const params = { model: modelId }
      return axiosIns
        .get('/api/v2/issues', { params })
        .then(({ data }) => {
          commit('SET_ISSUES', data)
          commit('SET_UNCERTAINTIES_STATUS', apiStatus.LOADED)
        })
        .catch(e => console.error(e))
    },
    selectIssue: ({ commit }, issueId) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService.get(`/v1/issues/${issueId}`, config)
        .then(({ data }) => {
          commit('SET_SELECTED_ISSUE', data)
        }).catch(e => console.error(e))
    },
    createIssue: ({ commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService.post('/v1/issues', payload, config)
        .then(({ data }) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully created Issue',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          return data
        }).catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create Issue',
              text: `Server error: ${e.response.data.detail}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateIssue: ({ dispatch }, issueDetails) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId } }
      return coreService.put(`/v1/issues/${issueDetails.id}`, issueDetails.data, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully updated Issue',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          dispatch('getIssues')
        }).catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Issue',
              text: `Server error: ${e.response.data.detail}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    allocateIssue: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId, label: payload.label } }
      let { allocated } = payload
      if (allocated.constructor !== Array) allocated = [allocated]
      return coreService.post(`/v1/issues/allocate/${payload.issueId}`, allocated, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully allocated Issue',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }).catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to allocate Issue',
              text: `Server error: ${e.response.data.detail}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    allocateIssues: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'model-id': modelId, label: payload.label } }
      return coreService.post(`/v1/issues/bulk_allocate/${payload.target_id}`, payload.issues, config)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully allocated Issues',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }).catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to allocate Issues',
              text: `Server error: ${e.response.data.detail}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
