<template>
  <b-form>
    <list-group-behaviour
      label="Behaviours"
      :entity-array="issue.behaviours"
      class="mb-2"
    />
    <list-group-entity
      pre-label="Allocated"
      label="Components"
      :entity-array="issue.components"
      modal="associator-generic-components-issue-context"
      class="mb-2"
    />
    <list-group-interface
      label="Interfaces"
      :entity-array="issue.interfaces"
      modal="associator-generic-interfaces-issue-context"
      class="mb-2"
    />
    <list-group-requirement
      label="Requirements"
      :entity-array="issue.requirements"
      modal="associator-generic-requirements-issue-context"
      class="mb-2"
    />
    <list-group-test
      label="Test Cases"
      :entity-array="issue.tests"
      modal="associator-generic-test-cases-issue-context"
      class="mb-2"
    />

    <div id="issue_context_modals">
      <associator-generic
        name="Components"
        suffix="-issue-context"
        :associated-items="issue.components.map(reduce)"
        :all-items="allComponents"
        @associated="allocateIssue('Component', $event)"
      />
      <associator-generic
        name="Interfaces"
        suffix="-issue-context"
        :associated-items="issue.interfaces.map(reduce)"
        :all-items="allInterfaces"
        @associated="allocateIssue('Interface', $event)"
      />
      <associator-generic
        name="Requirements"
        suffix="-issue-context"
        :associated-items="issue.requirements.map(reduce)"
        :all-items="allReqs"
        @associated="allocateIssue('Requirement', $event)"
      />
      <associator-generic
        name="Test Cases"
        suffix="-issue-context"
        :associated-items="issue.tests.map(reduce)"
        :all-items="allTests"
        @associated="allocateIssue('TestCase', $event)"
      />
    </div>
  </b-form>
</template>

<script>
import store from '@/store'
import { ref, onMounted } from '@vue/composition-api'

import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import ListGroupBehaviour from '@/components/Forms/ListGroups/ListGroupBehaviour.vue'
import ListGroupInterface from '@/components/Forms/ListGroups/ListGroupInterface.vue'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import ListGroupTest from '@/components/Forms/ListGroups/ListGroupTest.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'

export default {
  components: {
    AssociatorGeneric,
    ListGroupTest,
    ListGroupRequirement,
    ListGroupInterface,
    ListGroupBehaviour,
    ListGroupEntity,
  },
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const allComponents = ref([])
    const allInterfaces = ref([])
    const allReqs = ref([])
    const allTests = ref([])

    onMounted(() => {
      store.dispatch('domainModel/getComponentsSimple').then(() => {
        allComponents.value = store.state.domainModel.components.map(reduce)
      })
      store.dispatch('interfaces/fetchInterfaces').then(() => {
        allInterfaces.value = store.state.interfaces.interfaces.map(reduce)
      })
      store.dispatch('requirements/getRequirementsSimple').then(() => {
        allReqs.value = store.state.requirements.requirements.map(reduce)
      })
      store.dispatch('tests/getTests').then(() => {
        allTests.value = store.state.tests.tests.map(reduce)
      })
    })

    const reduce = obj => ({ value: { id: obj.id }, text: obj.display_id || obj.name })

    const allocateIssue = (label, allocated) => {
      const payload = {
        issueId: props.issue.id,
        label,
        allocated,
      }
      store.dispatch('issues/allocateIssue', payload).then(() => {
        store.dispatch('issues/selectIssue', props.issue.id)
      })
    }

    return {
      allComponents,
      allInterfaces,
      allReqs,
      allTests,

      reduce,
      allocateIssue,
    }
  },
}
</script>
